<template>
    <div>
        <div class="content">
            <h1 class="title">
                {{ $t('calendar.header') }}
            </h1>
            <p class="subtitle">
                {{ $t('calendar.description') }}
            </p>
        </div>

        <FullCalendar :options="calendarOptions" />

        <b-modal
            :active.sync="makeReservation"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
        >
            <ReservationModal v-bind="formProps" />
        </b-modal>

        <b-modal
            :active.sync="setupBookingSlot"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
        >
            <BookingSlotModal v-bind="formProps" />
        </b-modal>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ReservationModal from '../../components/ReservationModal'
import BookingSlotModal from '../../components/BookingSlotModal'
import { parseJSON, parseISO, isAfter, addSeconds } from 'date-fns'

export default {
    name: 'ManageCalendar',
    components: { FullCalendar, ReservationModal, BookingSlotModal },
    data() {
        return {
            calendarOptions: {
                initialView: 'timeGridWeek',
                weekends: true,
                firstDay: 1,
                locale: 'sv',
                selectable: true,
                selectMirror: true,
                nowIndicator: true,
                events: this.fetchEvents,
                slotMinTime: '08:00:00',
                slotMaxTime: '20:00:00',
                allDayContent: 'heldag',
                height: 'auto',
                aspectRatio: '2',
                datesSet: this.$parent.show,
                select: this.createEvent,
                dateClick: this.editEvent,
                eventClick: this.$parent.createBooking,
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                customButtons: {
                    editMode: {
                        text: this.$t('calendar.enableEditMode'),
                        click: this.enableEditMode
                    }
                },
                headerToolbar: {
                    right: 'editMode today prev,next'
                },
                buttonText: {
                    today: this.$t('calendar.buttons.today'),
                    month: this.$t('calendar.buttons.month'),
                    week: this.$t('calendar.buttons.week'),
                    day: this.$t('calendar.buttons.day'),
                    list: this.$t('calendar.buttons.list')
                }
            },
            makeReservation: false,
            setupBookingSlot: false,
            editMode: false,
            formProps: {
                date: null,
                allDay: null,
                start: null,
                end: null
            }
        }
    },
    computed: {
        events() {
            return this.$store.getters['event/events']
        },
        bookings() {
            return this.$store.getters['event/bookings']
        },
        calendarTimeslots() {
            return this.$store.getters['calendar/timeslots']
        },
        bookingSlots() {
            const cleaned = this.calendarTimeslots.reduce((base, timeslot) => {
                let className = isAfter(new Date(), parseISO(timeslot.startAt))
                    ? 'pastEvent'
                    : ''
                if (timeslot.notValid === 1) {
                    return base
                }

                let end = addSeconds(
                    parseISO(timeslot.startAt),
                    timeslot.length
                )

                base.push({
                    title: this.$t('calendar.event.title'),
                    start: timeslot.startAt,
                    end: end,
                    id: timeslot.uuid,
                    color: '#66bb6a',
                    className: className
                })
                return base
            }, [])

            return cleaned
        },
        calendarBookings() {
            const cleaned = this.bookings.reduce((base, booking) => {
                let className = isAfter(
                    new Date(),
                    parseJSON(booking.confirmedDate)
                )
                    ? 'pastEvent'
                    : ''

                let end = addSeconds(
                    parseJSON(booking.confirmedDate),
                    booking.product.length
                )

                base.push({
                    title:
                        booking.locationRoute +
                        ' ' +
                        booking.locationStreetNumber,
                    start: parseJSON(booking.confirmedDate),
                    end: end,
                    id: booking.uuid,
                    color: booking.product.rgbColor || null,
                    className: className,
                    url: '/booking/' + booking.uuid
                })
                return base
            }, [])

            return cleaned
        }
    },
    methods: {
        fetchEvents(fetchInfo, successCallback) {
            setTimeout(async () => {
                successCallback(this.bookingSlots)
            }, 800)
        },
        enableEditMode() {
            this.editMode = !this.editMode
        },
        async createEvent(info) {
            if (this.editMode) {
                this.setupBookingSlot = true
            } else {
                this.makeReservation = true
            }
            this.formProps = info
            // console.log(info)
            /**
             *   visibleRange: {
             *   start: moment().subtract(4, "d"),
             *   end: moment().add(3, "d")
             */
        },
        async editEvent(info) {
            this.makeReservation = true
            this.formProps = info
        }
    }
}
</script>

<style lang="scss">
.editMode-enabled {
    background-color: red !important;
}
</style>
