<template>
    <div class="content">
        <h1>Bokningskalender här</h1>
    </div>
</template>

<script>
export default {
    name: 'BookingCalendar'
}
</script>
