<template>
    <section class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <ManageCalendar v-if="isSeller" />
                <BookingCalendar v-else />
            </div>
        </div>
    </section>
</template>

<script>
import ManageCalendar from '../components/calendar/ManageCalendar'
import BookingCalendar from '../components/calendar/BookingCalendar'
import format from '@/_lib/format'
import { isAfter } from 'date-fns'

export default {
    name: 'Calendar',
    components: { ManageCalendar, BookingCalendar },
    data() {
        return {}
    },
    computed: {
        isSeller() {
            return this.$store.getters['security/hasRole']('ROLE_SELLER')
        }
    },
    methods: {
        createBooking(info) {
            if (isAfter(new Date(), info.event.start)) return
            this.$router.push({
                name: 'NewBooking',
                params: {
                    calendar: { start: info.event.start, end: info.event.end }
                }
            })
        },
        async show(arg) {
            let start = format(arg.view.activeStart, "yyyy-MM-dd'T'HH:mm")
            let end = format(arg.view.activeEnd, "yyyy-MM-dd'T'23:59")
            await this.$store.dispatch('event/list', { start: start, end: end })
            await this.$store.dispatch('event/bookings', {
                start: start,
                end: end
            })
            await this.$store.dispatch('calendar/list', {
                start: start,
                end: end
            })
        }
    }
}
</script>

<style lang="scss">
.pastEvent {
    opacity: 0.3 !important;
    cursor: not-allowed;
}

.is-four-fifths {
    margin: 0 auto;
}
</style>
