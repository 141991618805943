<template>
    <div>
        <div class="modal-card is-relative" :width="740">
            <b-loading
                :is-full-page="false"
                :active.sync="isLoading"
                :can-cancel="false"
            />
            <header class="modal-card-head is-info">
                <p class="modal-card-title">
                    {{ $t('bookingSlotModal.header') }}
                </p>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column is-3">
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="1">
                                {{ $t('bookingSlotModal.weekdays.monday') }}
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="2">
                                {{ $t('bookingSlotModal.weekdays.tuesday') }}
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="3">
                                {{ $t('bookingSlotModal.weekdays.wednesday') }}
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="4">
                                {{ $t('bookingSlotModal.weekdays.thursday') }}
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="5">
                                {{ $t('bookingSlotModal.weekdays.friday') }}
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="6">
                                {{ $t('bookingSlotModal.weekdays.saturday') }}
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox v-model="weekdays" native-value="0">
                                {{ $t('bookingSlotModal.weekdays.sunday') }}
                            </b-checkbox>
                        </div>
                    </div>
                    <div class="column">
                        <div class="content">
                            <b-field
                                :label="$t('bookingSlotModal.howOften')"
                                class="is-block"
                            >
                                <b-radio
                                    v-model.number="event.everyOddEven"
                                    name="name"
                                    native-value="0"
                                    checked
                                >
                                    {{ $t('bookingSlotModal.everyWeek') }}
                                </b-radio>
                                <b-radio
                                    v-model.number="event.everyOddEven"
                                    name="name"
                                    native-value="1"
                                >
                                    {{ $t('bookingSlotModal.oddWeeks') }}
                                </b-radio>
                                <b-radio
                                    v-model.number="event.everyOddEven"
                                    name="name"
                                    native-value="2"
                                >
                                    {{ $t('bookingSlotModal.evenWeeks') }}
                                </b-radio>
                            </b-field>
                        </div>
                        <div class="content">
                            <b-field grouped>
                                <b-field
                                    :label="$t('bookingSlotModal.startsAt')"
                                >
                                    <b-input
                                        v-model="event.startAt"
                                        placeholder="Begins at"
                                        icon="clock"
                                        class="slotmodalinput"
                                        required
                                    />
                                </b-field>

                                <b-field
                                    :label="
                                        $t('bookingSlotModal.lengthInMinutes')
                                    "
                                >
                                    <b-input
                                        v-model.number="event.length"
                                        placeholder="Length (min)"
                                        class="slotmodalinput"
                                    />
                                </b-field>
                            </b-field>
                        </div>
                        <div class="content">
                            <div class="field">
                                <b-checkbox checked>
                                    {{ $t('bookingSlotModal.strictMode') }}
                                </b-checkbox>
                                <p class="is-size-7">
                                    {{
                                        $t(
                                            'bookingSlotModal.strictModeDescription'
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <b-checkbox v-model="event.inactive" date="block">
                        <p>{{ $t('bookingSlotModal.inactivate') }}</p>
                        <span class="is-size-7">
                            {{ $t('bookingSlotModal.inactivateDescription') }}
                        </span>
                    </b-checkbox>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    class="is-primary"
                    icon-left="bell-check-outline"
                    :disabled="!checkRequired"
                    @click="saveSlot"
                >
                    {{ $t('bookingSlotModal.buttons.save') }}
                </b-button>
            </footer>
        </div>
    </div>
</template>

<script>
import format from '@/_lib/format'
import {
    differenceInMinutes,
    // getHours,
    // getMinutes,
    // startOfWeek,
    getDay
    // set
} from 'date-fns'

export default {
    name: 'BookingSlotModal',
    props: {
        date: Date,
        allDay: Boolean,
        start: Date,
        end: Date
    },
    data() {
        return {
            weekdays: [],
            event: {
                everyOddEven: 0,
                startAt: null,
                length: null,
                inactive: false,
                fullDate: null
            },
            eventFormReset: null
        }
    },
    computed: {
        now() {
            return new Date()
        },
        isLoading() {
            return this.$store.getters['calendar/isLoading']
        },
        hasError() {
            return this.$store.getters['calendar/hasError']
        },
        checkRequired() {
            return true
            /* return (
                this.event.weekday.length > 0 &&
                this.event.oddEven !== null &
                this.event.startAt && this.event.length
            ) */
        }
    },
    methods: {
        saveSlot() {
            this.weekdays.forEach(async (week, index) => {
                /*
                let date = startOfWeek(new Date(), {
                    locale: { code: 'sv-SE' },
                    weekStartsOn: 1
                })
                
                set(date, {
                    hours: getHours(this.event.fullDate),
                    minutes: getMinutes(this.event.fullDate)
                })
                */
                let event = {
                    ...this.event,
                    startAt: format(this.event.fullDate, 'yyyy-MM-dd HH:mm'),
                    weekday: parseInt(week)
                }
                await this.$store.dispatch('calendar/create', event)

                if (!this.hasError) {
                    this.$parent.close()
                    this.$buefy.snackbar.open({
                        indefinite: false,
                        message: this.$t('bookingSlotModal.submit.success'),
                        type: 'is-success',
                        position: 'is-bottom-left',
                        actionText: 'OK',
                        queue: true
                    })

                    this.event = { ...this.eventFormReset }
                }
            })
        }
    },
    created() {
        // let beginAt = !this.allDay ? this.start : this.date
        let startAt = this.start
        let endAt = !this.allDay ? this.end : this.end
        this.weekdays.push(getDay(startAt))
        this.event = {
            everyOddEven: 0,
            startAt: format(startAt, 'HH:mm'),
            length: differenceInMinutes(endAt, startAt),
            inactive: false,
            fullDate: startAt
        }
        this.eventFormReset = { ...this.event }
    }
}
</script>

<style>
.slotmodalinput {
    width: 200px !important;
}
</style>
